import { FuseNavigationItem } from "@fuse/components/navigation";

export default function AdminNav() {
  const nav: FuseNavigationItem[] = [
    {
      id: "crm",
      title: "CRM",
      subtitle: "Interactua con los clientes",
      type: "group",
      icon: "heroicons_outline:home",
      children: [
        {
          id: "dashboard-v2",
          title: "Dashboard",
          type: "basic",
          icon: "heroicons_outline:chart-pie",
          link: "/dashboard/main",
        },
        {
          id: "crm.clientes",
          title: "Clientes",
          type: "basic",
          icon: "heroicons_outline:users",
          link: "/crm/customers",
        },
        {
          id: "crm.opportunity",
          title: "Oportunidades",
          type: "basic",
          icon: "heroicons_outline:shopping-cart",
          link: "/crm/opportunity",
        },

        {
          id: "activities",
          title: "Calendario de Actividades",
          type: "basic",
          icon: "heroicons_outline:calendar",
          link: "/crm/activities",
        },
        {
          id: "customersWallet",
          title: "Asesores de venta",
          type: "basic",
          icon: "heroicons_outline:briefcase",
          link: "/crm/salesAdvisors",
        },
        {
          id: "crm.invoice",
          title: "Facturas",
          type: "basic",
          icon: "heroicons_outline:document-text",
          link: "/crm/invoice",
        },
        {
          id: "crm.delivery-note",
          title: "Entregas",
          type: "basic",
          icon: "mat_outline:delivery_dining",
          link: "/crm/delivery-note",
        },
        {
          id: "crm.sale-order",
          title: "Ordenes de venta",
          type: "basic",
          icon: "mat_outline:point_of_sale",
          link: "/crm/sale-order",
        },
        {
          id: "usersProspects",
          title: "Leads",
          type: "basic",
          icon: "heroicons_outline:user",
          link: "/crm/prospects",
        },
        {
          id: "crm.business-partners",
          title: "Crear socios de negocio",
          type: "basic",
          icon: "heroicons_outline:user",
          link: "/crm/business-partners",
        },
        {
          id: "pending",
          title: "Pendiente por aprobar",
          type: "collapsable",
          icon: "heroicons_outline:check",
          children: [
            {
              id: "pending.discount",
              title: "Descuentos",
              type: "basic",
              link: "/crm/pending/discount",
            },
          ],
        },
        {
          id: "reports",
          title: "Reportes",
          type: "collapsable",
          icon: "heroicons_outline:folder-open",
          children: [
            {
              id: "report.customers_advisor",
              title: "Clientes por asesores",
              type: "basic",
              link: "/crm/report/customers-advisor",
            },
            {
              id: "report.quoted_products_customer",
              title: "Productos cotizados por clientes",
              type: "basic",
              link: "/crm/report/quoted-products-client",
            },
            {
              id: "report.advisor_goal",
              title: "Metas de Asesores",
              type: "basic",
              link: "/crm/report/advisor-goal",
            },
            {
              id: "report.top_quote",
              title: "Productos mas cotizados",
              type: "basic",
              link: "/crm/report/top-quote",
            },

            {
              id: "report.top_quote_by_cliente",
              title: "Clientes interesados por producto",
              type: "basic",
              link: "/crm/report/clients-by-quoted-product",
            },
            {
              id: "report.top_sold",
              title: "Productos mas vendidos",
              type: "basic",
              link: "/crm/report/top-sold",
            },
            {
              id: "report.advisor_activities",
              title: "Actividades de los asesores",
              type: "basic",
              link: "/crm/report/advisor-activities",
            },
            {
              id: "report.opportunity_origins",
              title: "Origenes de oportunidades",
              type: "basic",
              link: "/crm/report/opportunity-origins",
            },
            {
              id: "report.master",
              title: "Reporte de ventas",
              type: "basic",
              link: "/crm/report/master",
            },
            {
              id: "report.master_quotations",
              title: "Maestro Cotizaciones",
              type: "basic",
              link: "/crm/report/master-quotations",
            },
            {
              id: "report.master-leads",
              title: "Maestro Leads",
              type: "basic",
              link: "/crm/report/master-leads",
            },
            {
              id: "report.master-clients",
              title: "Maestro Clientes",
              type: "basic",
              link: "/crm/report/master-client",
            },
            {
              id: "report.master-products",
              title: "Maestro Productos",
              type: "basic",
              link: "/crm/report/master-product",
            },
            {
              id: "report.top-customers",
              title: "Top ventas clientes",
              type: "basic",
              link: "/crm/report/top-customers",
            },
            {
              id: "report.participation-by-subcategory",
              title: "Participación por subcategoría",
              type: "basic",
              link: "/crm/report/participation-by-subcategory",
            },
            {
              id: "report.inventory-rotation",
              title: "Rotación de inventario",
              type: "basic",
              link: "/crm/report/inventory-rotation",
            },
            {
              id: "report.inventory-analysis-by-warehouse",
              title: "Análisis de inventario por almacén",
              type: "basic",
              link: "/crm/report/inventory-analysis-by-warehouse",
            },
          ],
        },
      ],
    },
    {
      id: "inventory",
      title: "Inventario",
      subtitle: "Administra los productos",
      type: "group",
      icon: "heroicons_outline:home",
      children: [
        {
          id: "inventory.products",
          title: "Productos",
          type: "basic",
          icon: "heroicons_outline:shopping-bag",
          link: "/inventory/products",
        },

        {
          id: "inventory.categoryProducts.category",
          title: "Categorías",
          type: "basic",
          icon: "heroicons_outline:tag",
          link: "/inventory/categoryProducts/categories",
        },
        {
          id: "inventory.categoryProducts.subcategory",
          title: "Sub categorías",
          type: "basic",
          icon: "heroicons_outline:tag",
          link: "/inventory/categoryProducts/subcategories",
        },
        {
          id: "inventory.characteristic",
          title: "Caracteristicas",
          type: "basic",
          icon: "heroicons_outline:search-circle",
          link: "/inventory/characteristics",
        },
      ],
    },
    {
      id: "ecommerce",
      title: "Ecommerce",
      subtitle: "Gestiona los datos del ecommerce",
      type: "group",
      icon: "heroicons_outline:home",
      children: [
        {
          id: "ecommerce.businessData",
          title: "Datos de la empresa",
          type: "basic",
          icon: "heroicons_outline:database",
          link: "/ecommerce/businessData",
        },
        {
          id: "ecommerce.branch",
          title: "Sucursales",
          type: "basic",
          icon: "heroicons_outline:office-building",
          link: "/ecommerce/branch",
        },
        {
          id: "ecommerce.heroSlider",
          title: "Hero Slider",
          type: "basic",
          icon: "heroicons_outline:collection",
          link: "/ecommerce/heroSlider",
        },
        {
          id: "ecommerce.opinions",
          title: "Opiniones",
          type: "basic",
          icon: "heroicons_outline:pencil",
          link: "/ecommerce/opinions",
        },
        {
          id: "ecommerce.banners",
          title: "Banners",
          type: "basic",
          icon: "heroicons_outline:newspaper",
          link: "/ecommerce/banners",
        },
      ],
    },
    {
      id: "globalConfig",
      title: "Configuraciones Globales",
      subtitle: "Configura datos, tipos de datos y estados",
      type: "group",
      icon: "heroicons_outline:home",
      children: [
        {
          id: "globalConfig.productionPlant",
          title: "Plantas de producción",
          type: "basic",
          icon: "heroicons_outline:home-modern",
          link: "/globalConfig/production-plant",
        },
        {
          id: "globalConfig.pricelists",
          title: "Listas de precio",
          type: "basic",
          icon: "heroicons_outline:currency-dollar",
          link: "/globalConfig/pricelists",
        },
        {
          id: "globalConfig.warehouses",
          title: "Almacenes",
          type: "basic",
          icon: "heroicons_outline:cube",
          link: "/globalConfig/warehouses",
        },
        {
          id: "globalConfig.documentType",
          title: "Tipos de Documentos",
          type: "basic",
          icon: "heroicons_outline:document",
          link: "/globalConfig/documentType",
        },
        {
          id: "globalConfig.typeOrigins",
          title: "Tipos de Origen",
          type: "basic",
          icon: "mat_outline:account_tree",
          link: "/globalConfig/origin/typeOrigins",
        },
        {
          id: "globalConfig.typeActivity",
          title: "Tipos de Actividades",
          type: "basic",
          icon: "heroicons_outline:calendar",
          link: "/globalConfig/typeActivity",
        },
        {
          id: "globalConfig.measureUnit",
          title: "Unidades de Medida",
          type: "basic",
          icon: "heroicons_outline:variable",
          link: "/globalConfig/measureUnit",
        },
        {
          id: "globalConfig.winReasons",
          title: "Razones de ganancia",
          type: "basic",
          icon: "heroicons_outline:trending-up",
          link: "/globalConfig/winReasons",
        },
        {
          id: "globalConfig.lossReasons",
          title: "Razones de perdida",
          type: "basic",
          icon: "heroicons_outline:trending-down",
          link: "/globalConfig/lossReasons",
        },
        {
          id: "globalConfig.regions",
          title: "Regiones",
          type: "basic",
          icon: "heroicons_outline:globe",
          link: "/globalConfig/regions",
        },
      ],
    },
  ];

  return nav;
}
